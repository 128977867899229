<template>
  <Page full fixed class="overflow-hidden">
    <Schedule class="ml-6" :reference="`${companyId}-schedule`" />
  </Page>
</template>

<script setup>
import { onUnmounted, computed } from 'vue'
import Schedule from '../schedule/Schedule.vue'
import useSchedule from '@/components/schedule/Schedule'
import { useStore } from 'vuex'

const { resetGanttAndSchedule } = useSchedule()
const $store = useStore()

const companyId = computed(() => $store.state.session.company.company_id)

onUnmounted(() => {
  resetGanttAndSchedule()
})
</script>

<style lang="scss" rel="stylesheet/scss"></style>
